<template>
  <v-pagination
    v-if="total > 0"
    :length="pageLength"
    :value="page"
    @input="handleChange"
  ></v-pagination>
</template>
<script>
export default {
  name: "pagination",
  props: {
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    handleChange(e) {
      this.$emit("change", e);
    },
  },
  computed: {
    pageLength() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
};
</script>
