<template>
  <v-container fluid class="pt-0">
	  <div class="text-h5">设备列表</div>
	  <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>


	  <v-container>
		  <v-row no-gutters>
			  <v-col cols="2">
				  <v-btn color="success" style="height: 40px" @click="dialog = true">添加设备</v-btn>
			  </v-col>
			  <v-col cols="8" offset="2">
				  <v-row dense>
					  <v-col cols="3">
						  <v-select v-model="search.project" :items="filters.projects" dense hide-details item-text="name"
						            item-value="id" label="所属项目" outlined></v-select>
					  </v-col>
					  <v-col cols="3">
						  <v-select :items="filters.equips" dense hide-details label="设备名称" outlined></v-select>
					  </v-col>
					  <v-col cols="3">
						  <v-select :items="filters.sensorType" dense hide-details label="传感器类型" outlined></v-select>
					  </v-col>
					  <v-col class="text-center" cols="3">
						  <v-btn color="primary" style="height: 40px">查询</v-btn>
					  </v-col>
				  </v-row>
			  </v-col>
		  </v-row>
		  <v-row class="mt-3" no-gutters>
			  <v-col cols="12">
				  <Table ref="table" :columns="table_headers" :data="table_data" :loading="!(table_data.length>0)" border
				         disabled-hover height="750" highlight-row>
					  <template slot="update_time" slot-scope="{ row }">
						  <span>{{ dateFormat(row.update_time, 'long') }}</span>
					  </template>
				  </Table>
			  </v-col>
		  </v-row>
		  <v-row class="mt-3" no-gutters>
			  <v-col cols="6">
				  <span style="line-height:42px">共 {{ total }} 条记录</span>
			  </v-col>
			  <v-col cols="6">
				  <paginations
						  :page="search.page"
						  :page-size="search.pagesize"
						  :total="total"
						  style="float: right"
						  @change="(e) => doGet(e)"
				  ></paginations>
			  </v-col>
		  </v-row>

	  </v-container>

	  <v-dialog v-model="dialog" max-width="650">
		  <v-card>
			  <v-card-title class="headline">添加一个新的设备</v-card-title>

			  <v-card-text class="pb-1">
				  <strong>接口说明：</strong>
				  <p class="my-0">1.需要您在数据采集器端获取数据后，向本平台的设备数据接口主动发起HTTP请求。</p>
				  <p class="my-0">2.请先提交新设备的名称和简介，获取到返回到App_Key后，在新设备上配置，按照下方示例到数据格式发出数据。</p>
			  </v-card-text>

        <v-simple-table class="ma-2" style="border:1px solid #eeeeee">
	        <tbody>
	        <tr>
		        <td class="text-left" style="width:20%">所属项目:</td>
		        <td class="text-left" style="width:80%">
			        <v-select v-model="equip_form.project" :items="filters.projects" dense hide-details item-text="name"
			                  item-value="id" label="所属项目" outlined></v-select>
		        </td>
	        </tr>

	        <tr>
		        <td class="text-left" style="width:20%">设备名称:</td>
		        <td class="text-left" style="width:80%">
			        <v-text-field v-model="equip_form.name" dense hide-details outlined></v-text-field>
		        </td>
	        </tr>
	        <tr>
		        <td class="text-left" style="width:20%">设备描述:</td>
		        <td class="text-left" style="width:80%">
			        <v-text-field v-model="equip_form.description" dense hide-details outlined></v-text-field>
		        </td>
	        </tr>
	        <tr>
		        <td class="text-right" colspan="2">
			        <v-btn color="green darken-1" dark @click="addEquipment()">
				        提交
			        </v-btn>
		        </td>
	        </tr>
	        <tr>
		        <td class="text-left" style="width:20%">接口(POST):</td>
		        <td class="text-left" style="width:80%">
			        https://cqc.mtec.cc/api/sensordata/
		        </td>
	        </tr>

	        <tr>
		        <td class="text-left">App_Key:</td>
		        <td :class="equip_form.app_key?'':'grey--text'" class="text-left">
			        {{ equip_form.app_key ? equip_form.app_key : '提交后获取app_key' }}
		        </td>
	        </tr>
	        <tr>
		        <td class="text-left">数据示例</td>
		        <td class="text-left">
			        <code lang="json">
				        {
				        "app_key" :"{{ equip_form.app_key ? equip_form.app_key : '提交后获取app_key' }}",
				        "data":[
				        {
				        "equip_id":"4357",
				        "equip_name": "测点4357",
				        "sensor_id": "4357-0001",
				        "sensor_name": "甲醛传感器",
				        "value": "0.052"
				        },
				        ...
				        ]
	                }
                </code>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            取消
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import moment from "moment";
import {getSensordataList, addEquipment} from "@/api/equipments";
import {getProjectList} from "@/api/projects";
import paginations from "@/components/paginations";

export default {
	name: "Equipments",
	components: {
		paginations,
	},
	methods: {
		// details(id) {
		// 	this.$router.push({ name: "project_details", params: { id } });
		// },
		doGet(page = this.search.page) {
			this.search.page = page;
			getSensordataList({page: page}).then((res) => {
				this.table_data = res.results;
				this.total = res.count;
			});
		},
		// changeSearch(e) {
		// 	this.search.name = e;
		// },

		//格式化时间
		dateFormat(date, type = "short") {
			let format = "YYYY-MM-DD HH:mm:ss";
			if (type === "short") {
				format = "YYYY-MM-DD";
			}
			return moment(date).format(format);
		},
		getSensordataList() {
			this.table_data = [];
			getSensordataList({page: 1, pagesize: 100}).then((res) => {
				this.total = res.count
				this.table_data = JSON.parse(JSON.stringify(res.results))
				console.log('sensor', res)
			})
		},
		getProjectList() {
			let list = []
			getProjectList({page: 1, pagesize: 10000}).then((res) => {
				res.results.map(item => {
					list.push({id: item.id, name: item.name})
				})
			})
			this.filters.projects = list
		},
		addEquipment() {
			let postData = this.equip_form
			// console.log('project',this.id)
			addEquipment(postData).then(res => {
				console.log('add', res)
				this.equip_form.app_key = res.app_key
			}).catch(error => {
				console.log('error', error)
				alert('发生错误！请重新填写新设备的信息')
			})
		}
	},
	data() {
		return {
			total: 0,
			dialog: false,
			search: {
				page: 1,
				pagesize: 100,
				project: null,
			},
			filters: {
				projects: [],
				equips: [],
				sensorType: []
			},
			equip_form: {
				project: null,
				name: '',
				code: "",
				type: 1,
				description: '',
				app_key: ""
			},
			table_headers: [
				{
					title: '#',
					type: 'index',
					width: "60",
				},
				{
					title: '设备名称',
					key: 'equip_name',
					sortable: true,
				},
				{
					title: '传感器名称',
					key: 'sensor_name',
					sortable: true,
				},
				{
					title: '设备ID',
					key: 'equip_id',
					sortable: true,
					width: "140",
					align: 'center',
				},
				{
					title: '传感器ID',
					key: 'sensor_id',
					sortable: true,
					width: "140",
					align: 'center'
				},
				{
					title: '示数',
					key: 'value',
					width: "180",
					align: 'center'
				},
				{
					title: '更新时间',
					slot: 'update_time',
					sortable: true,
					width: "180",
					align: 'center'
				},

			],
			table_data: [],
		}

	},
	mounted() {
		this.getSensordataList()
		this.getProjectList()
	},

};
</script>
<style lang="stylus" scoped></style>
